import { environment } from "environments/environment";
import { map } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class MockService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.config = {};
        this.httpClient.get(`${environment.apiBaseUrl}/mock/config`).subscribe(res => {
            this.config = res;
        }, err => {
            console.error(err);
        });
    }
    loadNotifications() {
        return this.httpClient
            .get(`${environment.apiBaseUrl}/notification`)
            .pipe(map(response => response));
    }
    checkNotification(id) {
        return this.httpClient
            .patch(`${environment.apiBaseUrl}/notification/${id}/checked`, {})
            .pipe(map(response => response));
    }
    setConfig(config) {
        this.config = config;
    }
    getConfig() {
        return this.config;
    }
}
MockService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MockService_Factory() { return new MockService(i0.ɵɵinject(i1.HttpClient)); }, token: MockService, providedIn: "root" });
