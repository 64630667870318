import { Routes } from "@angular/router";
import "hammerjs";
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { AuthGuard } from './auth/services/auth.guard';
registerLocaleData(localeFr, 'fr');
import { IConfig } from 'ngx-mask';
const maskConfig = {
    validation: false,
};
const ɵ0 = () => import("./auth/login/login.module.ngfactory").then(m => m.LoginModuleNgFactory), ɵ1 = () => import("./auth/password/password.module.ngfactory").then(m => m.PasswordModuleNgFactory), ɵ2 = () => import("./main/admin-management/admin-management.module.ngfactory").then(m => m.AdminManagementModuleNgFactory), ɵ3 = () => import("./main/album/album.module.ngfactory").then(m => m.AlbumModuleNgFactory), ɵ4 = () => import("./main/analytic/analytic.module.ngfactory").then(m => m.AnalyticModuleNgFactory), ɵ5 = () => import("./main/track/track.module.ngfactory").then(m => m.TrackModuleNgFactory), ɵ6 = () => import("./main/video/video.module.ngfactory").then(m => m.VideoModuleNgFactory), ɵ7 = () => import("./main/news/news.module.ngfactory").then(m => m.NewsModuleNgFactory), ɵ8 = () => import("./main/users/users.module.ngfactory").then(m => m.UsersModuleNgFactory), ɵ9 = () => import("./main/show/show.module.ngfactory").then(m => m.ShowModuleNgFactory), ɵ10 = () => import("./main/podcasts/podcasts.module.ngfactory").then(m => m.PodcastsModuleNgFactory), ɵ11 = () => import("./main/slides/slides.module.ngfactory").then(m => m.SlidesModuleNgFactory), ɵ12 = () => import("./main/playlist/playlist.module.ngfactory").then(m => m.PlaylistModuleNgFactory), ɵ13 = () => import("./main/credit/credit.module.ngfactory").then(m => m.CreditModuleNgFactory), ɵ14 = () => import("./shared/file-manager/file-manager.module.ngfactory").then(m => m.FileManagerModuleNgFactory), ɵ15 = () => import("./main/content/content.module.ngfactory").then(m => m.ContentModuleNgFactory), ɵ16 = () => import("./main/archive/archive.module.ngfactory").then(m => m.ArchiveModuleNgFactory), ɵ17 = () => import("./shared/404/error-404.module.ngfactory").then(m => m.Error404ModuleNgFactory);
const appRoutes = [
    // Athentifications routes will be loaded in the root path
    {
        path: "login",
        loadChildren: ɵ0,
        canActivate: [AuthGuard],
    },
    {
        path: "password",
        loadChildren: ɵ1,
        canActivate: [AuthGuard]
    },
    {
        path: "admin",
        loadChildren: ɵ2,
        canActivate: [AuthGuard]
    },
    {
        path: "album",
        loadChildren: ɵ3,
        canActivate: [AuthGuard]
    },
    {
        path: "stats",
        loadChildren: ɵ4,
        canActivate: [AuthGuard]
    },
    {
        path: "track",
        loadChildren: ɵ5,
        canActivate: [AuthGuard]
    },
    {
        path: "video",
        loadChildren: ɵ6,
        canActivate: [AuthGuard]
    },
    {
        path: "news",
        loadChildren: ɵ7,
        canActivate: [AuthGuard]
    },
    {
        path: "users",
        loadChildren: ɵ8,
        canActivate: [AuthGuard]
    },
    {
        path: "shows",
        loadChildren: ɵ9,
        canActivate: [AuthGuard]
    },
    {
        path: "podcasts",
        loadChildren: ɵ10,
        canActivate: [AuthGuard]
    },
    {
        path: "slides",
        loadChildren: ɵ11,
        canActivate: [AuthGuard]
    },
    {
        path: "playlists",
        loadChildren: ɵ12,
        canActivate: [AuthGuard]
    },
    {
        path: "credits",
        loadChildren: ɵ13,
        canActivate: [AuthGuard]
    },
    {
        path: "explorer",
        loadChildren: ɵ14, canActivate: [AuthGuard]
    },
    {
        path: "content",
        loadChildren: ɵ15,
        canActivate: [AuthGuard]
    },
    {
        path: "archive",
        loadChildren: ɵ16,
        canActivate: [AuthGuard]
    },
    {
        path: "",
        redirectTo: "album",
        pathMatch: "full"
    },
    {
        path: "**",
        loadChildren: ɵ17,
        canActivate: [AuthGuard]
    }
];
export class AppModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17 };
